body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #121212;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-x: hidden;
}

.App {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.hero-section {
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(145deg, rgba(245, 0, 87, 0.5), rgba(255, 64, 129, 0.2));
  border-radius: 12px;
  position: relative;
}

.hero-content {
  z-index: 2;
  position: relative;
}

.logo-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.hero-logo-bg {
  background-color: #1f1f1f;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(255, 64, 129, 0.7);
}

.hero-logo {
  max-width: 80px;
  filter: drop-shadow(0 0 10px rgba(255, 64, 129, 0.7));
}

.hero-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ff4081;
  text-shadow: 0 0 10px rgba(255, 64, 129, 0.7);
}

.cta-button {
  background-color: #ff4081;
  border-color: #ff4081;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 10px 30px;
  border-radius: 30px;
  text-shadow: 0 0 5px rgba(255, 64, 129, 0.7);
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

.cta-button:hover {
  background-color: #ff79a2;
  border-color: #ff79a2;
}

.screenshots-text-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 40px;
}

.screenshot {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
}

.download-and-text {
  text-align: left;
}

.hero-description {
  margin-bottom: 20px;
  font-size: 1.2rem;
  line-height: 1.5;
}

.download-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.store-badge {
  height: 50px;
}

.features-section {
  margin-top: 50px;
}

.feature-card {
  background: #1f1f1f;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.feature-card h2 {
  color: #ffffff;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.feature-card p {
  color: #d1d1d1;
  font-size: 1rem;
}

.footer {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  background: #1f1f1f;
  border-radius: 12px;
}

.footer-link {
  color: #ff4081;
}